$base-font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$base-font-size-web: 14px;

//********************************************APPLICATION COLORS*********************************************

// BRAND COLORS
$brand-color-1: #CC0000;
$brand-color-2: #1C1E1C;
$brand-color-3: #FFFFFF;
$brand-color-4: #333333;
$brand-color-5: #858585;
$brand-color-6: #DFDFDF;

// FOUNDATIONAL PALETTE
$foundational-color-1: #FAFAFA;
$foundational-color-2: #F5F5F5;
$foundational-color-3: #EEEEEE;
$foundational-color-4: #E0E0E0;
$foundational-color-5: #BDBDBD;
$foundational-color-6: #9E9E9E;
$foundational-color-7: #757575;
$foundational-color-8: #616161;
$foundational-color-9: #424242;
$foundational-color-10: #212121;

// ACTION COLORS
$action-color-1: #0091EA;
$action-color-2: #80D8FF;

// ACCENT COLORS
$accent-color-1: #00C853;
$accent-color-2: #64DD17;
$accent-color-3: #AEEA00;
$accent-color-4: #0091EA;
$accent-color-5: #00B8D4;
$accent-color-6: #00BFA6;
$accent-color-7: #6200EA;
$accent-color-8: #304FFE;
$accent-color-9: #2962FF;
$accent-color-10: #D50000;
$accent-color-11: #C51162;
$accent-color-12: #AA00FF;
$accent-color-13: #DD2C00;
$accent-color-14: #FF6D00;
$accent-color-15: #FFD600;

$color-17: rgba(0, 0, 0, 0.54);

$highlight-color: #FFFBEC;

// INTENTIONAL COLOR
$color-error: $accent-color-10;
$app-background: $foundational-color-4;
$nav-background: $foundational-color-3;

// LAYOUT
$content-panel-vertical-padding: 20px;
$content-panel-horizontal-padding: 20px;
$content-panel-spacing: 7px;
$form-item-spacing: 16px;

// SCREEN SIZES for media queries
$laptop-width: 1200px;
$tablet-landscape-width: 1100px;
$tablet-portrait-width: 800px;
$phone-portrait-width: 600px;

// CONTENT
$content-width: 1234px;

// LAYOUT
$header-height: 20px;
$toolbar-height: 56px;
$footer-height: 40px;
$gutter-size: 28px;
$dialog-width: 450px;
$dialog-height: 100px;


// ==== Layout ====
$xpo-contentSpacing: 16px;

// **   XPO Core UI imports   **
// **********************************************

// ==== Colors ====
$xpo-blue--100: #E8F5FE;
$xpo-blue--150: #E3F2FD;
$xpo-blue--300: #0D94F4;
$xpo-blue--350: #2196F3;
$xpo-blue--400: #0868AC;
$xpo-blue--650: #0087EA;
$xpo-blue--700: #0091EA;
$xpo-blue--800: #1976D2;
$xpo-blue--850: #0D47A1;
$xpo-blue--900: #053C63;

$xpo-yellow--100: #FFF8E5;
$xpo-yellow--300: #FFCF4D;
$xpo-yellow--400: #FFBA00;
$xpo-yellow--700: #FFD600;
$xpo-yellow--900: #4D3800;

$xpo-red--100: #FFEEEE;
$xpo-red--300: #FF2222;
$xpo-red--400: #D50000;
$xpo-red--700: #D32F2F;
$xpo-red--900: #550000;

$xpo-green--100: #C8FFDF;
$xpo-green--300: #1CE855;
$xpo-green--400: #11A63B;
$xpo-green--700: #00C853;
$xpo-green--900: #0A6122;

$xpo-grey--50: #FBFBFB;
$xpo-grey--100: #F3F3F3;
$xpo-grey--200: #E6E6E6;
$xpo-grey--80: #F6F6F6;
$xpo-grey--300: #D8D8D8;
$xpo-grey--350: #BDBDBD;
$xpo-grey--400: #9B9B9B;
$xpo-grey--600: #757575;
$xpo-grey--700: #6F6F6F;
$xpo-grey--750: #575757;
$xpo-grey--900: #4A4A4A;
$xpo-grey--930: #424242;
$xpo-grey--950: #333333;
$xpo-grey--1000: #171717;
$xpo-grey--1100: #212121;

$xpo-orange--100: #FFDFCA;
$xpo-orange--300: #FF9957;
$xpo-orange--400: #FF6400;
$xpo-orange--900: #8B3700;

$xpo-purple--700: #6200EA;

$xpo-pink--700: #C51162;

$xpo-black: #000;
$xpo-white: #FFF;

// ==== Colors Applied ====
$xpo-fontColor--primary: $xpo-grey--900;
$xpo-fontColor--link: $xpo-blue--400;
// this one should be moved to app
$xpo-backgroundColor--body: $xpo-grey--100;

// ==== Status Colors ====
$xpo-color--error: $xpo-red--400;
$xpo-color--success: $xpo-green--400;
$xpo-color--warn: $xpo-yellow--400;
$xpo-color--info: $xpo-grey--900;

// ==== Typography ====
// Putting sizes in maps to allow dynamic sizing for external
// Root font-size(s) always in px
$xpo-fontSize--root: 14px;
$xpo-fontSize--small: 0.875rem; //12px
$xpo-fontSize--medium: 1rem; //14px
$xpo-fontSize--large: 1.143rem; //16px
$xpo-fontSize--extraLarge: 1.25rem; //20px

$xpo-header-fontSize--small: 1.143rem; //16px
$xpo-header-fontSize--medium: 1.285rem; //18px
$xpo-header-fontSize--large: 1.428rem; //20px

$xpo-fontFamily--primary: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
$xpo-fontFamily--robotoWideBlack: 'Roboto Wide Black', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
$xpo-fontFamily--robotoBlack: 'Roboto-Black', 'Roboto Black', 'Roboto', sans-serif;

$xpo-fontWeight--light: 300;
$xpo-fontWeight--normal: 400;
$xpo-fontWeight--medium: 500; // DEPRECATED | do not delete since it is still being used in some projects
$xpo-fontWeight--bold: 500;
$xpo-fontWeight--extraBold: 600;

$xpo-lineHeight--base: 24px;  // for medium and large font size
$xpo-lineHeight--small: 16px; // for small font size
$xpo-lineHeight--medium: 18px; // for medium font sizes
$xpo-header-lineHeight--small: 1.143;
$xpo-header-lineHeight--medium: 1.143;
$xpo-header-lineHeight--large: 1.286;

$xpo-letterSpacing--base: 0.25px;
$xpo-header-letterSpacing--small: 0.29px;
$xpo-header-letterSpacing--medium: 0.33px;
$xpo-header-letterSpacing--large: 0.38px;

// ==== Layout ====
$xpo-contentSpacing: 16px;

// Breakpoints
$xpo-breakpoint--small-xs: 480px;
$xpo-breakpoint--small: 600px;
$xpo-breakpoint--medium: 768px;
$xpo-breakpoint--large: 1025px;
$xpo-breakpoint--xLarge: 1440px;

$xpo-select-backgroundColor-optionOnHover: #E4EFFA;

// -- NGX-UI Specific --
$ngx-footer-height: 40px;
$ngx-tab-header-height: 40px;
$ngx-table-row-height: 32px;

$ngx-icon-size: 24px;

$ngx-theBlackRoboto: 900;

$ngx-input-row-height: 32px;

$ngx-input-border-width: 1px;

$ngx-input-color-onHover: $xpo-grey--100;
$ngx-input-color-onActive--dark: $xpo-black;
$ngx-input-color-onActive--light: $xpo-white;
$ngx-input-color-border: $xpo-grey--300;
$ngx-input-color-disabled--dark: $xpo-grey--350;
$ngx-input-color-disable--lighter: $xpo-white;
$ngx-input-color-disabled--light: $xpo-grey--80;

$ngx-button-lineHeight: 36px;
$ngx-button-lineHeight--small: 28px;
$ngx-button-lineHeight--large: 46px;
$ngx-button-animationDuration: 200ms;

$ngx-header-height: 40px;
// For use when a hover is needed on a white background.
$ngx-whiteAffordance-onHover-color: $xpo-grey--100;

$ngx-menu-backgroundColor: #FFF;

$ngx-Snackbar-status--success: #4CAF50;
$ngx-Snackbar-status--error: #D32F2F;
$ngx-Snackbar-status--info: #0288D1;
$ngx-Snackbar-status--warn: #FBC02D;

$ngx-Snackbar-dismiss--success: #388E3C;
$ngx-Snackbar-dismiss--warn: #F57F17;
$ngx-Snackbar-dismiss--error: #B71C1C;
$ngx-Snackbar-dismiss--info: #01579B;
$ngx-SnackBar-marginTop: 32px;

//Badge
$xpo-Badge--important: #CC0000;
$_matBadgeContent-backgroundColor--negative : #FCEEEB;
$_matBadgeContent-color--negative : #DD2C00;
$_matBadgeContent-backgroundColor--positive : #F1F9F1;

$ngx-menu-backgroundColor-onHover: #FCFCFC;
// Used as a spacer for flexed children
// TODO: Move all instances to the utilities definition
.flex--stretch {
  flex: 1;
}

// --- Dialog ---
$ngx-dialog-header-height: 48px;
$ngx-dialog-footer-height: 64px;

// Transitions
$xpo-transition-200: all 200ms ease-out;

// --- Contextual Header ---
$ngx-ContextualHeader-separator--color: $xpo-grey--350;
