/* You can add global styles to this file, and also import other style files */

@import 'app-variables';

html,
body {
  font-family: $base-font-family;
  font-size: $base-font-size-web;
  height: 100%;
  margin: 0;
  width: 100%;
}

a {
  color: $action-color-1;
  text-decoration: none;
}

form {
  .read-only-field {
    &__container {
      margin-top: -4px;
      padding: 0 4px;
    }

    &__label {
      color: $foundational-color-7;
      font-size: 12px;
      margin-bottom: 8px;
    }

    &__value {
      // use if needed
    }

  }
}

.u-truncateText {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
