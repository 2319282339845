@import './app-variables.scss';

$icons-path: '~ag-grid-community/src/styles/ag-theme-base/icons/';
$ag-mat-icons-path: '~ag-grid-community/src/styles/material-icons/';

// changes the border color
// $border-color: #FF0000;

// Changes the font size
// $font-size: 12px;

// Changes the font family
// $font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,
// "Helvetica Neue", sans-serif;

// Reverts the cell horizontal padding change between ag-fresh and ag-theme-fresh
// $cell-horizontal-padding: 2px;

// changes the default icon color
$icon-color: #303F9F;
// changes the selected checkbox color
$accent-color: #FFA000;


// TODO: Review Material theme
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import '~ag-grid-community/src/styles/ag-theme-material/sass/legacy/ag-theme-material-v22-compat.scss';

.ag-row:hover {
  background-color: #F2F2F2;
}

.ag-cell {
  align-items: center;
  border-style: none !important;
  display: flex;
  padding-left: 8px !important;
  padding-top: 0 !important;
  padding-right: 8px !important;
}

.ag-cell-wrapper {
  display: flex;
}

.ag-header-cell {
  padding-left: 8px !important;
  padding-right: 0 !important;
}

.ag-header-cell-label .ag-header-cell-text {
  align-items: center;
  display: flex;
}

.ag-header-cell-text {
  color: #666666;
  font-size: 12px;
  font-weight: 500;
}

.ag-header-select-all {
  margin-top: 2px !important;
  padding-left: 8px;
  padding-top: 2px;
}

.ag-cell-value {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
}

.ag-cell-focus {
  border: none !important;
}

.checkbox {
  margin-left: 15px;
  padding-top: 23px;
  width: 35px;
}

.ag-cell {

  &.checkbox_edit {
    display: flex;
    justify-content: center;

    i {
      font-size: 21px;
    }
  }

  &.index {
    display: block;
    text-align: center;
  }
}

.ag-selection-checkbox {
  align-items: center;
  display: flex;
}

.ag-header-row {
  padding-top: 4px;
  width: 100% !important;

  .ag-header-cell:first-child {
    margin-left: -4px;
  }
}

.ag-body-container {
  overflow: hidden;
  width: 100%;
}

#myGrid .ag-body-container {
  width: 100% !important;
}

.ag-header-cell {
  line-height: 56px;

  &-label {
    margin-top: -3px;

    .ag-header-icon {
      align-items: center;
      display: flex;
    }
  }
}
